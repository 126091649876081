import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from "../views/LoginView.vue";
import Layout from "@/components/layout/Layout";
import Otp from "@/views/Otp";

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'userLogin',
            component:  LoginView
        },
        {
            path:'Otp',
            name: 'Otp',
            component: Otp

        },

        {
            path: '/',
            component:  Layout,
            children: [
                {
                    path: '/expiring-customers',
                    name: 'expiring-customers',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName : "expiring-customers" */ "@/views/ExpiringCustomersView")
                },

                {
                    path: '/invoice-customers',
                    name: 'invoice-customers',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName : "invoice-customers" */ "@/views/InvoiceCustomersView")
                },
                {
                    path: '/update-invoice',
                    name: 'update-invoice',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName : "update-invoice" */ "@/views/UpdateInvoice")
                },
                {
                    path: '/customers',
                    name: 'customers',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName : "customers" */ "@/views/Allcustomers")
                },
                {
                    path: '/InvoiceHistory',
                    name: 'InvoiceHistory',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName : "customers" */ "@/views/InvoiceHistoryView.vue")
                },
                {
                    path: '/CustomerDevices/:storeID',
                    name: 'CustomerDevices',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName : "customers" */ "@/views/CustomerDevices.vue")
                },
                {
                    path: '/mobile-users/:mainCustomerID',
                    name: 'mobile-users',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName : "mobile-users" */ "@/views/MobileUsersView")
                },
            ],
        },
    ]
})

export default router
