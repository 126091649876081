import Vue from "vue";
import Vuex from "vuex";
import TimeManager from "../../services/TimeManager";

Vue.use(Vuex);

export default {
    name: "time",
    state: {
        date_range : TimeManager.getThisMonth(),
        activeTab : 2,
    },
    getters: {
        getGlobalDateRange   : (state) => state.date_range,
        getActiveTab : (state) => state.activeTab,
    },
    mutations: {
        setGlobalDateRange : (state, data) => state.date_range = data,
        setActiveTab : (state, data) => state.activeTab = data,
    },
    actions: {}
}
