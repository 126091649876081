export const ADMIN = 'dealerAccount'

const notEmpty = (val) => {
    if (!val) return false
    return val !== ''
}
const encryptAdmin = (val) => {
    return window.btoa(val);
}
const decryptAdmin = (val) => {
    if (!val) return undefined;
    if (val.toString().charAt(0)===`"`){
        val = val.toString().slice(1, -1);
    }
    return JSON.parse(window.atob(val))
}

const getValue = (key) => {
    let value = localStorage.getItem(key);
    if (key === ADMIN){
        return decryptAdmin(value)
    }
    return JSON.parse(value);
}
const setValue = (key , value) => {
    value = JSON.stringify(value);
    if (key === ADMIN){
        value = encryptAdmin(value)
    }
    localStorage.setItem(key,value)
}

export const Storage = {
    setAdminUser: (payload) => setValue(ADMIN,payload),
    getAdminUser: () => getValue(ADMIN),
    getDealerID: () => {
        let admin = getValue(ADMIN);
        if (!admin) return null;
        return admin.delaerId;
    },
}
