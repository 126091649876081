<template>
  <div class="container-fluid navigation-bar p-1 px-2">
    <button @click="toggle" class="burger">
      <font-awesome-icon icon="fa-solid fa-bars"/>
    </button>

    <div class="navbar-logo"></div>
    <div class="navigation-box"></div>

    <div class="user-settings">
      <button @click="syncData" class="burger">
        <font-awesome-icon icon="fa-solid fa-arrows-rotate"/>
      </button>
    </div>
  </div>
</template>

<script>


export default {
  name: "NavigationBar",
  props: {
    toggle: {
      type: Function,
      require: true
    },
  },
  methods: {
    syncData() {
      return window.location.reload(true);
    },
  }
}
</script>

<style scoped>
.burger {
  width: 40px;
  height: 40px;
  background: transparent;
  color: white;
  font-size: 30px;
}

.user-settings {
  display: flex;
  flex-direction: row;
  justify-content: end;
}
</style>
