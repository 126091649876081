<template>
  <div id="app">
    <Loader/>
    <router-view/>
  </div>
</template>

<script>

import Loader from "@/components/layout/Loader";

export default {
  components: {Loader},
  beforeMount() {

  },
  computed: {
    loading() {
      return this.$store.getters.getLoadingState;
    },
  },
  methods: {
    syncData() {
      return Promise.all([
        this.$store.commit(`setCurrentLoggedInUser`),
      ])
    }
  }
}
</script>
<style lang="scss">
@import url(./assets/css/styles.scss);
</style>
