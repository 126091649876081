<template>
  <div class="drawer" v-if="curUser">
    <div class="user-avatar">
      <div v-if="curUser" class="avatar-details">
        <h5>{{ curUser.usersname+` `+curUser.userssurname }}</h5>
        <p>{{ curUser.email }}</p>
        <p>{{ curUser.usertype }}</p>
      </div>
    </div>
    <div class="routes">
      <ul>
        <li v-if="isUpdateAvailable">
          <a @click="updateApp">
            <div class="icon-box">
              <font-awesome-icon class="icon text-info" icon="fa-solid fa-exclamation-circle"/>
            </div>
            <div class="d-flex flex-column text-center">
              Update Is Available
              <span class="text-sm" @click="updateApp">Click to Update</span>
            </div>i
          </a>
        </li>

        <li >
          <router-link @click.native="back" :to="{ name : 'customers' }">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="fa-solid fa-file-invoice-dollar"/>
            </div>
            All Customers
          </router-link>
        </li>

        <li >
          <router-link @click.native="back" :to="{ name : 'invoice-customers' }">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="fa-solid fa-file-invoice-dollar"/>
            </div>
            Invoices
          </router-link>
        </li>

        <li>
          <router-link @click.native="back" :to="{ name : 'expiring-customers' }">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="fa-solid fa-history"/>
            </div>
            Expiring Customers
          </router-link>
        </li>

        <li >
          <router-link @click.native="back" :to="{ name : 'InvoiceHistory' }">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="fa-solid fa-history"/>
            </div>
            Invoice History
          </router-link>
        </li>

        <li>
          <router-link @click.native="back" :to="{ name : 'userLogin' }">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="fa-solid fa-arrow-right-from-bracket"/>
            </div>
            Log out
          </router-link>
        </li>
      </ul>
    </div>
  </div>

</template>

<script>
import { Storage } from '@/services/Storage'
export default {
  name: "NavigationDrawer",
  props: {
    close: {
      type: Function,
      required: true,
    }
  },
  computed: {
    isUpdateAvailable(){
      return this.$store.getters.getUpdateAvailability;
    },
    curUser(){
      let user = Storage.getAdminUser();
      if (!user)return this.$router.push({ name : 'userLogin' })
      return user;
    },
  },
  methods: {
    back() {
      if (this.close) {
        this.close();
      } else {
        console.log("Close not defined")
      }
    },
    updateApp(){
      this.back();
      this.$store.commit(`setUpdateAvailability`,false)
      return window.location.reload(true);
    },
  }
}
</script>

<style lang="scss" scoped>
.disabled {
  pointer-events: none;
  display: none;
}

.drawer {
  width: 100%;
  background: white;
  height: 100%;
  overflow-y: auto;

  .routes {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding: 0;

        a {
          height: 50px;
          display: flex;
          flex-direction: row;
          align-items: center;
          font-weight: 600;
          padding-left: 10px;
          text-decoration: none;
          color: #1c1c1c;

          .icon-box {
            width: 35px;
            margin-right: 10px;
            display: flex;
            justify-content: center;

            .icon {
              font-size: 25px;
              color: #34373C;
            }
          }

          &:hover, &.router-link-exact-active {
            background: #f1f1f1;
            color: #1e88e5;
            font-weight: 700;

            .icon-box {
              .icon {
                color: #1e88e5;
              }
            }
          }
        }
      }
    }
  }

  .user-avatar {
    display: inline-flex;
    width: 100%;
    background: url('/src/assets/img/play-banner.png') fixed;
    background-size: cover;
    color: white;
    min-height: 140px;
    background-color: rgba(50, 70, 80, 0.7);
    background-blend-mode: soft-light;

    .avatar {
      background: #40434A;
      min-width: 80px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }

    .avatar-details {
      width: 100%;
      padding: 5px 5px 5px 10px;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;

      h5, p {
        margin: 0;
      }

      p {
        font-size: 15px;
        color: #ffffff;
        font-weight: 400;

        &:nth-child(3) {
          color: #c0d9ef;
          font-weight: 600;
        }
      }

    }
  }
}

ul {
  text-align: center;

  span {
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-weight: bold;
  }
}

.last-upd {
  font-weight: bold;
}
</style>
