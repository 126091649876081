<template>
  <div class="Otp">
    <form @submit.prevent="submitForm" class="Otp-form">

      <a @click="backToLogin">
        Back to Log In
        <font-awesome-icon class="icon" icon="fa-solid fa-lock"/>
      </a>

      <h2>OTP Verification</h2>

      <div class="form-group">
        <label for="Otp">Enter OTP</label>
        <input
            type="text"
            v-model="Otp"
            id="Otp"
            required
        />
      </div>

      <a @click="resendOtp" class="back-to-login">Resend OTP</a>

      <button type="submit" :disabled="loading">
        <span v-if="loading">Loading...</span>
        <span v-else>Submit</span>
      </button>

    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      Otp: '',
      loading: false,
      numberOfEncryptions: 18,
    };
  },
  methods: {
    submitForm() {
      this.loading = true;
      return axios.post( subscriptionApi + 'dealer/verifyOtp', {
        otp: this.Otp,
      })
          .then(({ data }) => {
            responses.showSuccess('OTP verified successfully');
            localStorage.setItem('session-id', data.sessionId);
            this.$router.push({ name: 'customers' });
          })
          .catch(err => {
            errorHandler.tomcatError(err, false, 'OTP Verification Failed');
          })
          .finally(() => {
            this.loading = false;
          });
    },
    backToLogin() {
      this.$router.push({ name: 'Login' });
    },
    resendOtp() {
      this.loading = true;
      let auth = localStorage.getItem('auth');
      for (let i = 0; i < this.numberOfEncryptions; i++) {
        auth = window.atob(auth);
      }
      auth = JSON.parse(auth);

      return axios.post(subscriptionApi+'dealer/refreshOTP', auth)
          .then(() => {
            responses.showSuccess('OTP resent successfully');
          })
          .catch(err => {
            errorHandler.tomcatError(err, false, 'Failed to resend OTP');
          })
          .finally(() => {
            this.loading = false;
          });
    }
  }
};
</script>

<style scoped>
.Otp {
  background-color: #f2f2f2;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666;
}

.Otp-form {
  background-color: #ffffff;
  padding: 2rem;
  width: 350px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.75);
}

.resend-otp-link {
  color: #666;
  text-decoration: none;
  background-color: transparent;
  margin-bottom: 1.5rem;
}

.resend-otp-link:hover {
  background-color: transparent;
}
.back-to-login {
  color: #666;
  text-decoration: none;
  background-color: transparent;
}

.back-to-login:hover {
  background-color: transparent;
  color: #333;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}

h2 {
  font-weight: 700;
  font-size: 36px;
  color: #007bff;
  margin-bottom: 1rem;
}

label {
  font-weight: 500;
  margin-bottom: 0.5rem;
}

input {
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 0.6rem;
  border: 1px solid rgba(213, 212, 212, 0.95);
}

button {
  padding: 10px;
  border: none;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border-radius: 10px;
  cursor: pointer;
}

button:hover {
  background-color: #f2f2f2;
  color: #007bff; /* Changed to primary blue */
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

@media only screen and (max-width: 600px) {
  .Otp-form {
    width: 320px;
  }

  input {
    margin-bottom: 0.3rem;
  }

  button {
    padding: 8px;
  }
}

</style>

