import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default {
    name: "mobileUsers",
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        createMobileUser : (context , payload) => {
            return axios.post(subscriptionApi+`mobileUser`,payload)
                .catch(err=>errorHandler.tomcatError(err))
        },
        getUsersByMainCustomerID : (context , mainCustomerID) => {
            return axios.get(subscriptionApi+`mobileUser/getByMainCustomerID/${mainCustomerID}`)
                .then(({data}) => data).catch(err=>errorHandler.tomcatError(err))
        }
    }
}
