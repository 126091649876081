const { LocalDate } = JSJoda;

const { DateTimeFormatter, Duration, LocalDateTime } = JSJoda;
const { Locale } = JSJodaLocale;

let addIntForDate = (num) => num >= 10 ? num : '0' + num;

const defaultFormat = DateTimeFormatter.ofPattern("yyyy-MM-dd HH:mm:ss")
    .withLocale(Locale.ENGLISH)

const thisYearFormat = DateTimeFormatter.ofPattern("E dd MMM, HH:ss")
    .withLocale(Locale.ENGLISH);

const otherYearFormat = DateTimeFormatter.ofPattern("dd/MM/yyyy, HH:ss")
    .withLocale(Locale.ENGLISH)

const todayFormat = DateTimeFormatter.ofPattern("HH:mm")
    .withLocale(Locale.ENGLISH)

let tomorrow = LocalDate.now().plusDays(1);
tomorrow = tomorrow._year + '-' + addIntForDate(tomorrow._month) + '-' + addIntForDate(tomorrow._day);

let yesterday = LocalDate.now().minusDays(1);
yesterday = yesterday._year + '-' + addIntForDate(yesterday._month) + '-' + addIntForDate(yesterday._day);

class TimeRangeManager {

    shortenDisplay(parsedDate) {
        if (!parsedDate) return `Invalid Date : ${parsedDate}`
        let formattedDate = LocalDateTime.parse(parsedDate, DateTimeFormatter.ofPattern("yyyy-MM-dd HH:mm:ss"))
        let now = LocalDateTime.now()
        if (formattedDate.toLocalDate().equals(now.toLocalDate())) {
            return `Today at ` + formattedDate.format(todayFormat)
        } else if (formattedDate.toLocalDate().year() === now.toLocalDate().year()) {
            return formattedDate.format(thisYearFormat)
        } else {
            return formattedDate.format(otherYearFormat)
        }
    }

    shortJavaDateTimeObject(javaDateTimeObject) {
        let date = javaDateTimeObject.date;
        let time = javaDateTimeObject.time;
        let parsedDate = `${date.year}-${addIntForDate(date.month)}-${addIntForDate(date.day)} ${addIntForDate(time.hour)}:${addIntForDate(time.minute)}:${addIntForDate(time.minute)}`;
        return this.shortenDisplay(parsedDate);
    }

    getNow() {
        let today = LocalDate.now();
        today = today._year + '-' + addIntForDate(today._month) + '-' + addIntForDate(today._day);
        return today;
    }

    getNowMinusDays(days) {
        let today = LocalDate.now().minusDays(days);
        today = today._year + '-' + addIntForDate(today._month) + '-' + addIntForDate(today._day);
        return today;
    }

    getToday() {
        let today = LocalDate.now();
        today = today._year + '-' + addIntForDate(today._month) + '-' + addIntForDate(today._day);
        return {from: today, to: tomorrow}
    }

    getYesterday() {
        let today = LocalDate.now();
        today = today._year + '-' + addIntForDate(today._month) + '-' + addIntForDate(today._day);
        return {from: yesterday, to: today}
    }

    getLastWeek() {
        let startOfWeek = LocalDate.now().minusDays(7);
        startOfWeek = startOfWeek._year + '-' + addIntForDate(startOfWeek._month) + '-' + addIntForDate(startOfWeek._day)
        return {from: startOfWeek, to: tomorrow}
    }


    getThisMonth() {
        let startOfMonth = LocalDate.now().withDayOfMonth(1)
            .format(DateTimeFormatter.ofPattern("yyyy-MM-dd"));
        return {from: startOfMonth, to: tomorrow}
    }

    getPreviousMonth() {
        let startOfMonth = LocalDate.now().withDayOfMonth(1)
            .format(DateTimeFormatter.ofPattern("yyyy-MM-dd"));

        let startOfLastMonth = LocalDate.now().minusMonths(1).withDayOfMonth(1)
            .format(DateTimeFormatter.ofPattern("yyyy-MM-dd"));

        return {from: startOfLastMonth, to: startOfMonth}
    }

    getThisYear() {
        let startOfYear = LocalDate.now()._year + '-01-01'
        return {from: startOfYear, to: tomorrow}
    }

    getForNoOfDays(noOfDays) {
        let startOfWeek = LocalDate.now().minusDays(noOfDays);
        startOfWeek = startOfWeek._year + '-' + addIntForDate(startOfWeek._month) + '-' + addIntForDate(startOfWeek._day)
        return {from: startOfWeek, to: tomorrow}
    }

    getDateDiff(date1, date2) {
        date1 = LocalDateTime.parse(date1 + ' 00:00:00', DateTimeFormatter.ofPattern("yyyy-MM-dd HH:mm:ss"))
        date2 = LocalDateTime.parse(date2 + ' 00:00:00', DateTimeFormatter.ofPattern("yyyy-MM-dd HH:mm:ss"))
        let duration = Duration.between(date1, date2);
        return duration.toDays();
    }

    isGreaterThanTwoMonths(range) {
        return this.getDateDiff(range.from, range.to) >= 60;
    }

    getExactCurrentDate() {
        let now = LocalDateTime.now();
        console.log(now)
        let date = now._date._year + '-' + addIntForDate(now._date._month) + '-' + addIntForDate(now._date._day);
        let time = addIntForDate(now._time._hour) + ':' + addIntForDate(now._time._minute) + ':' + addIntForDate(now._time._second);
        return date + ' ' + time;
    }

    parseDateTime(dateTimeStr) {
        // Splitting the date and time components from the string
        const [datePart, timePart] = dateTimeStr.split(' ');

        // Splitting the date into year, month, and day
        const [year, month, day] = datePart.split('-').map(Number);

        // Splitting the time into hour, minute, and second
        const [hour, minute, second] = timePart.split(':').map(Number);

        // Constructing the date and time object
        return {
            date: {
                year: year,
                month: month,
                day: day
            },
            time: {
                hour: hour,
                minute: minute,
                second: second,
                nano: 0  // Assuming nano should be set to 0 as there's no nano info in the input string
            }
        };
    }

}

export default TimeRangeManager = new TimeRangeManager();
