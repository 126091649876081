<template>
  <div class="page d-flex align-items-center justify-content-center">
    <form class="form d-flex flex-column gap-2" @submit.prevent="login">
      <h1 class="h3 mb-3 fw-normal">Sign in</h1>
      <div class="form-floating">
        <input type="email" v-model="loginDetails.email" class="form-control" id="floatingInput"
               placeholder="example@chrilantech.com" required>
        <label for="floatingInput">Email address</label>
      </div>
      <div class="form-floating">
        <input type="password" v-model="loginDetails.password" class="form-control" id="floatingPassword"
               placeholder="Password" required>
        <label for="floatingPassword">Password</label>
      </div>
      <button class="w-100 btn btn-lg btn-primary" :disabled="loading" type="submit">
        <span v-if="loading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
        {{ loadingText }}
      </button>
      <p class="mt-5 mb-3 text-body-secondary">©{{ new Date().getFullYear() }}</p>
    </form>
   </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "LoginView",
  computed : {
    loadingText(){
      return this.loading?`Signing in` : `Sign in`;
    },
  },
  data() {
    return {
      loading: false ,
      loginDetails: {
        email: "",
        password: ""
      }
    }
  },
  methods: {
    ...mapActions({
      signIn: 'login'
    }),
    login() {
      this.loading = true;
      return this.signIn(this.loginDetails)
          .then(() => this.$router.push({name: 'Otp'}))
          .finally(()=>this.loading = false)
    }
  }
}
</script>

<style scoped>
.page {
  width: 100vw;
  height: 100vh;
}

.form {
  height: 355px;
  width: 100%;
  max-width: 400px;
}
</style>