import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import customers from "@/store/module/customers";
import timeModule from "@/store/module/time-module";
import invoiceModule from "@/store/module/invoice-module";
import mobileUserMobile from "@/store/module/mobile-users";
import {Storage} from "@/services/Storage";

Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        expiringCustomers: [],
        invoicedCustomers: null,
        currentLoginUser: null,
        loading: false,
        isUpdateAvailable: false,

    },
    getters: {
        getCurrentLoggedInUser: (state) => state.currentLoginUser,
        getExpiringCustomers: (state) => state.expiringCustomers,
        getInvoicedCustomers: (state) => state.invoicedCustomers,
        getLoadingState: (state) => state.loading,
        getUpdateAvailability: (state) => state.isUpdateAvailable,
    },
    mutations: {
        setCurrentLoggedInUser: (state, data) => {
            if (!data) data = Storage.getAdminUser()
            else Storage.setAdminUser(data)
            state.currentLoginUser = data;
        },
        setExpiringCustomers: (state, data) => state.expiringCustomers = data,
        setInvoicedCustomers: (state, data) => state.invoicedCustomers = data,
        setLoadingState: (state, data) => state.loading = data,
        setUpdateAvailability: (state, data) => state.isUpdateAvailable = data,
    },
    actions: {
        login: (context, data) => {
            return axios.post(subscriptionApi + `dealer/login`, data)
                .then(({data}) => {
                    context.commit('setCurrentLoggedInUser', data)
                    return true;
                }).catch(err => {
                    errorHandler.tomcatError(err)
                    return false;
                })
        },
    },
    modules : { customers , timeModule , mobileUserMobile , invoiceModule },
});