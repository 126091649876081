import { render, staticRenderFns } from "./Otp.vue?vue&type=template&id=46385ae8&scoped=true&"
import script from "./Otp.vue?vue&type=script&lang=js&"
export * from "./Otp.vue?vue&type=script&lang=js&"
import style0 from "./Otp.vue?vue&type=style&index=0&id=46385ae8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46385ae8",
  null
  
)

export default component.exports