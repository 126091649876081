import store from '../store/store'

function formatNumber(decimalCount, amount, symbol, thousands = ",", decimal = ".") {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        // Convert the amount to a fixed-point notation
        amount = Number(amount).toFixed(decimalCount);

        // Use regex to remove unnecessary trailing zeros and the decimal point if it is an integer
        let [integerPart, fractionalPart] = amount.split('.');

        // Trimming the fractional part of trailing zeros if necessary
        if (fractionalPart) {
            fractionalPart = fractionalPart.replace(/0+$/, '');
        }

        let i = Math.abs(parseInt(integerPart)).toString(); // Ensure the integer part is positive for the split
        let j = i.length > 3 ? i.length % 3 : 0;

        return (
            symbol +
            ' ' +
            negativeSign +
            (j ? i.substr(0, j) + thousands : '') +
            i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
            (fractionalPart ? decimal + fractionalPart : '')
        );
    } catch (e) {
        console.error(e);
    }
}

export const utils = {
    toPascalCase(string) {
        return `${string.toString().toLowerCase()}`
            .replace(new RegExp(/[-_]+/, 'g'), ' ')
            .replace(new RegExp(/[^\w\s]/, 'g'), '')
            .replace(
                new RegExp(/\s+(.)(\w*)/, 'g'),
                ($1, $2, $3) => ` ${$2.toUpperCase() + $3.toLowerCase()}`
            ).replace(new RegExp(/\w/), s => s.toUpperCase())
            .trim();
    },
    cutLength(string, limit = 45) {
        if (string.length < limit) {
            return string
        } else {
            return string.toString().substring(0, limit).concat('...')
        }
    },
    formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",", isCurrency = true) {
        amount = this.roundAmount(amount,decimalCount);
        return formatNumber(decimalCount, amount, isCurrency?`K`:``, thousands, decimal);
    },

    formatQTY(amount) {
        amount = this.roundAmount(amount,3);
        return this.formatMoney(amount, 3, '.', ',',false);
    },

    roundAmount(amount , decimalPlaces = 2){
        if (typeof amount === 'string'){
            amount = amount.replace(`,`,``);
            amount = Number(amount);
        }
        amount = amount.toFixed(decimalPlaces);
        return Number(amount);
    },

    removeIf(arr, predicate) {
        return arr.filter(predicate);
    },
    remove(arr, obj) {
        return arr.filter(v => v !== obj)
    },



    getNow() {
        let date = new Date();
        return date.toISOString().split('T')[0];
    },

    showLoading: () => store.commit(`setLoadingState`, true),
    hideLoading: () => store.commit(`setLoadingState`, false),
}

