import Vue from 'vue'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faHouse,
    faBarcode,
    faCalendar,
    faPlus,
    faMinus,
    faBars,
    faAngleRight,
    faArrowLeft,
    faDeleteLeft,
    faPaperPlane,
    faClose,
    faCashRegister,
    faBoxes,
    faLock,
    faAddressCard,
    faPhone,
    faFileDownload,
    faCopy,
    faEye,
    faReceipt,
    faGear,
    faXmark,
    faChevronRight,
    faArrowRightFromBracket,
    faCreditCard,
    faMoneyBill,
    faChevronUp,
    faHistory,
    faArrowsRotate,
    faLayerGroup,
    faPenToSquare,
    faUsers,
    faUserPlus,
    faHandHoldingDollar,
    faCoins,
    faArrowRotateLeft,
    faGears,
    faMoneyBills,
    faArrowTrendDown,
    faPrint,
    faTrash,
    faTable,
    faSave,
    faChartLine,
    faUpload,
    faFolderTree,
    faPhoneAlt,
    faArrowsSplitUpAndLeft,
    faQuestionCircle,
    faChartSimple,
    faHandHoldingDroplet,
    faEnvelope,
    faExchange,
    faFileInvoiceDollar,
    faComputer,
    faUndo,
} from '@fortawesome/free-solid-svg-icons'

Vue.component('font-awesome-icon', FontAwesomeIcon)
library.add(
    faHouse, faComputer, faBarcode, faCalendar, faClose, faPlus, faMinus, faBars, faAngleRight, faArrowLeft,
    faDeleteLeft,  faPaperPlane, faCashRegister, faBoxes, faLock,faPhone,faFileInvoiceDollar,faFileDownload,
    faReceipt, faGear, faArrowRightFromBracket, faXmark, faChevronRight, faCreditCard, faMoneyBill, faChevronUp,
    faArrowsRotate, faLayerGroup, faPenToSquare, faUsers, faHandHoldingDollar, faCoins, faUserPlus, faArrowRotateLeft,faUndo,
    faGears, faMoneyBills, faArrowTrendDown, faPrint, faTrash,faTable,faSave, faArrowsRotate, faLayerGroup,faHistory,
    faPenToSquare, faUsers, faHandHoldingDollar, faCoins, faUserPlus,faChartLine,faUpload,faFolderTree,faCopy,faEye,
    faArrowsSplitUpAndLeft,faQuestionCircle,faChartSimple, faHandHoldingDroplet,faEnvelope,faExchange,faPhoneAlt,faAddressCard
)

