import axios from "axios";
import {Storage} from "@/services/Storage";

export default {
    state : {
        subscriptionProducts : [
            {
                Description2: " ",
                SellingIncl: 3000,
                archived: false,
                Department: "Software",
                FastLine: 0,
                Description: "Lyt-Soft POS Annual POS Sever",
                Subdep2: "",
                QTY: "0.000",
                PLU: "Soft5",
                TaxLabels: "A",
                InclCost: "0.00",
                SubDep: ""
            },
            {
                Description2: " ",
                SellingIncl: 300,
                archived: false,
                Department: "Software",
                FastLine: 1,
                Description: "Lyt-Soft Pos 1st Point Monthly",
                Subdep2: "",
                QTY: "0.000",
                PLU: "Lyt-SoftMon Ser",
                TaxLabels: "A",
                InclCost: "0.00",
                SubDep: "Monthly"
            },
            {
                Description2: " ",
                SellingIncl: 200,
                archived: false,
                Department: "Software",
                FastLine: 1,
                Description: "Lyt-Soft Pos Extra Point Monthly",
                Subdep2: "",
                QTY: "0.000",
                PLU: "Lyt-Soft Extra",
                TaxLabels: "A",
                InclCost: "0.00",
                SubDep: "Monthly"
            },
            {
                Description2: " ",
                SellingIncl: 2000,
                archived: false,
                Department: "Software",
                FastLine: 0,
                Description: "Lyt-Soft POS Annual POS Extra Point",
                Subdep2: "",
                QTY: "0.000",
                PLU: "Soft6",
                TaxLabels: "A",
                InclCost: "0.00",
                SubDep: ""
            },
            {
                Description2: "",
                SellingIncl: 50,
                archived: false,
                Department: "Software",
                FastLine: 0,
                Description: "Kazang POS Monthly Billing Per Device",
                Subdep2: "",
                QTY: "0.000",
                PLU: "kaz-pos",
                TaxLabels: "A",
                InclCost: "0.00",
                SubDep: "Monthly"
            },
        ]
    },
    getters: {
        getSubscriptionProducts:(state) => state.subscriptionProducts
    },

    actions: {
        getTableData : async (context , saleNumber) => {
            return axios.get(franchiseTomcatApi+`savetable/${mainDB}/${saleNumber}`)
                .then(({data}) => data).catch(err=>errorHandler.tomcatError(err))
        },
        updateInvoice:async (context , payload)=>{
            let user = await Storage.getAdminUser();
            payload = {
                email : user.email,
                name : user.usersname,
                surname : user.userssurname,
                ...payload,
            }
            return axios.put(subscriptionApi+`invoice/update`,payload)
                .catch(err=>errorHandler.tomcatError(err))
        }
    }
};